.worker-list {
    width: 90%;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.worker-list h2 {
    color: var(--text-color);
    margin-bottom: 20px;
    text-align: center;
}

.worker-list table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.worker-list th, .worker-list td {
    padding: 10px;
    border: 1px solid var(--border-color);
    text-align: left;
}

.worker-list th {
    background-color: var(--header-bg-color);
}

.worker-list tr:nth-child(even) {
    background-color: var(--hover-bg-color);
}

.worker-list button {
    padding: 10px 15px;
    border: none;
    margin: 10px 0px;
    background-color: var(--button-bg-color);
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    font-size: 16px;
}

.worker-list button:hover {
    background-color: var(--button-hover-bg-color);
}

.worker-list .close-button {

    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: var(--text-color);
}
