/* src/components/UserProfile/UserProfile.css */
.user-profile {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-left: 254px;
    overflow-x: auto;
    z-index: 2;
    margin-top: 20px;

  }
  
  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 20px;
  }
  
  .profile-details section {
    margin-bottom: 20px;
  }
  
  .detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
  }
  
  .detail strong {
    margin-right: 5px;
    color: #333;
  }
  .detail .w9-warning {
    color: red;
  }
  .user-profile input[type="text"] {
    padding: 8px;
    margin: 5px 0;
    box-sizing: border-box;
    width: calc(100% - 16px);  
  }
  
  .user-profile button {
    margin: 10px;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .user-profile button:hover {
    background-color: #0056b3;
  }
  @media (max-width: 768px) {
    .user-profile{
      margin: 0;
      margin-left: 26px;
      margin-bottom: 28px;
      margin-top: 10px;
    }
  }