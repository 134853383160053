.register-container {
    max-width: 400px;
    margin: 50px auto;

    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .register-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-container {
    position: relative;
  }
  
  .input-container i {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #999;
  }
  
  .input-container input {
    width: 100%;
    padding: 10px 10px 10px 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .input-container input:focus {
    border-color: #007bff;
  }
  
  .error {
    color: #d9534f;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .buttonRegister {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .buttonRegister:hover {
    background-color: #0056b3;
  }
  