.work-item-manager {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .user-selection {
    margin-bottom: 20px;
  }
  
  .user-selection select {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    border-radius: 4px;
  }
  
  .task-creation {
    margin-bottom: 20px;
  }
  
  .task-creation input {
    display: block;
    width: calc(100% - 20px);
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .task-creation button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .task-creation button:hover {
    background-color: #0056b3;
  }
  
  .task-list {
    margin-top: 20px;
  }
  
  .work-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #fff;
  }
  
  .work-item.completed {
    background-color: #e0ffe0;
  }
  
  .status-button,
  .delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .status-button.completed {
    background-color: #28a745;
    color: white;
  }
  
  .status-button {
    background-color: #007bff;
    color: white;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
    margin-left: 10px;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  