@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
    background-color: #f5f5f5;
}

.coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    color: #2b2b2b;
}

.lamp-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 300px;
    background-color: #f3f3f3;
}

.lamp {
    height: 37px;
    background-color: #004f72;
    border-radius: 50% 50% 0 0;
    position: relative;
    left: 12px;
}

.light {
    position: absolute;
    top: 40px;
    width: 150px;
    height: 150px;
    background: radial-gradient(ellipse at center, rgba(255, 255, 224, 0.6) 0%, rgba(255, 255, 224, 0) 80%);
    filter: blur(30px);
    z-index: 1;
}

.coming-soon h1 {
    font-size: 3em;
    font-weight: 700;
    margin: 20px 0;
    position: relative;
    color: #2b3e50;
    padding-top: 23px;
}

.logoComingSoon {
    position: relative;
    height: 174px;
    width: auto;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animação ao hover e clique */
}

.logoComingSoon:hover {
    transform: scale(1.1); /* Aumenta o logo ao passar o mouse */
}

.logoComingSoon:active {
    transform: scale(1.2); /* Aumenta mais ao clicar */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Sombra para dar efeito de saída */
}

.subtitle {
    color: #0A3350;
    font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.contact-item {
    text-align: center;
    padding: 8px 27px 9px 19px;
    color: #042136;
    background-color: #FFFFFF;
    border-radius: 15px;
}

.contact-item a {
    text-decoration: none;
    color: #2b3e50;
    font-weight: 600;
    font-size: 1.1em;
}

.icon {
    font-size: 1.5em;
    color: #2b3e50;
}
.user-button-container {
    position: absolute;
    top: 20px; /* Ajuste a posição conforme necessário */
    right: 20px;
}

.user-button {
    background-color: #004f72;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.user-button:hover {
    background-color: #003f5c;
    transform: scale(1.05);
}

.user-button:active {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .lamp{
        left: 7px !important;
    }
}