.header {
  background: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;
  width: 100%;
}

.logo {
  height: 50px; /* Ajuste a altura conforme necessário */
  width: auto;  /* Mantém a proporção do logo */
}
.logo img{
  background-color: aliceblue;
}
.header .logo {
  font-size: 24px;
  font-weight: bold;
}

.header nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.header nav ul li {
  margin-right: 20px;
}

.header .user-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 35px;
}

.botaoPerfil, .botaohome {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.botaoPerfil:hover, .botaohome:hover {
  background-color: #0056b3;
}

.header a {
  color: white;
  text-decoration: none;
}

.menu-toggle {
  display: none;
  cursor: pointer;
}

.menu-toggle .hamburger {
  width: 30px;
  height: 3px;
  background-color: white;
  position: relative;
}

.menu-toggle .hamburger::before,
.menu-toggle .hamburger::after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: white;
  position: absolute;
  left: 0;
}

.menu-toggle .hamburger::before {
  top: -10px;
}

.menu-toggle .hamburger::after {
  bottom: -10px;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .header {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 20px;
  }

  .header nav {
      display: none;
      width: 100%;
  }

  .header nav.open {
      display: block;
      margin-top: 10px;
  }

  .header nav ul {
      flex-direction: column;
      text-align: left;
      width: 100%;
      padding-left: 0;
  }

  .header nav ul li {
      display: block;
      margin: 5px 0;
  }

  .header .user-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 71%;
      margin-top: 10px;
  }

  .menu-toggle {
    display: block;
    position: absolute;
    top: 24px;
    right: 51px;
    align-self: flex-start;
  }

  .header .logo {
      align-self: flex-start;
      background-image: url('../../logo192.png');
  }
}
