/* src/WorkList.css */
.work-list {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 7px;
  }
  
  .work-list h2 {
    margin-bottom: 10px;
  }
  
  .work-list p {
    margin-bottom: 20px;
    color: #888;
  }
  
  .work-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .work-item:last-child {
    border-bottom: none;
  }
  
  .work-item-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #00c853; /* Green for completed */
    margin-right: 10px;
  }
  
  .work-item-content {
    flex-grow: 1;
  }
  
  .work-item h4 {
    margin: 0;
    font-size: 16px;
  }
  
  .work-item p {
    margin: 0;
    color: #888;
    font-size: 12px;
  }
  
  .status-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .status-button.completed {
    background: #b9f6ca; /* Light green for completed */
    color: #00c853;
  }
  
  .status-button:not(.completed) {
    background: #ffcdd2; /* Light red for pending */
    color: #d32f2f;
  }
  
  .completed .work-item-indicator {
    background: #00c853;
  }
  