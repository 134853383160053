/* Container principal */
.work-session-tracker {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  /* Título principal */
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Texto de erro */
  .error-text {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Alerta */
  .alert-box {
    display: none;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    text-align: center;
  }
  
 /* WorkSessionTracker.css */

.project-select {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.project-select label {
  margin-bottom: 8px;
  font-size: 1.2em;
  font-weight: bold;
  color: #333; /* Dark gray text */
}

.project-select select {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.project-select select:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

.project-select select option {
  padding: 10px;
}

  
.work-completed-message {
  text-align: center;
  padding: 20px;
  margin: 20px 0;
  background-color: #e0ffe0; /* Light green background */
  border: 1px solid #00cc00; /* Green border */
  border-radius: 10px;
  color: #006600; /* Dark green text */
  font-size: 1.2em;
  font-weight: bold;
}

.alert-box {
  text-align: center;
  padding: 10px;
  margin: 10px 0;
  background-color: #ffe0e0; /* Light red background */
  border: 1px solid #cc0000; /* Red border */
  border-radius: 5px;
  color: #660000; /* Dark red text */
  display: none; /* Hidden by default */
}

.project-select, .action-button, .summary-list {
  margin: 10px 0;
}

.action-button {
  display: inline-block;
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff; /* Blue background */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.action-button:disabled {
  background-color: #cccccc; /* Grey background for disabled buttons */
  cursor: not-allowed;
}

  
  /* Informações da sessão */
  .session-info {
    margin: 20px 0;
    padding: 15px;
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 8px;
  }
  
  .session-info p {
    margin: 10px 0;
    font-size: 16px;
    color: #555;
  }
  
  /* Estilo para as informações da sessão */
  .info-label {
    font-weight: bold;
    color: #333;
  }
  
  .info-value {
    display: inline-block;
    margin-left: 10px;
    color: #555;
    font-family: 'Courier New', Courier, monospace;
    background-color: #eef;
    padding: 2px 5px;
    border-radius: 4px;
  }
  
  /* Lista de resumo */
  .summary-list {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .summary-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 15px;
    background-color: #fdfdfd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .summary-item p {
    margin: 5px 0;
    font-size: 15px;
    color: #555;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .work-session-tracker {
      padding: 15px;
    }
  
    .action-button {
      padding: 10px 20px;
      margin: 8px 0;
      width: 100%;
      font-size: 14px;
    }
  
    .session-info {
      padding: 10px;
    }
  
    .summary-item {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .action-button {
      padding: 8px 16px;
      font-size: 12px;
    }
  
    .summary-item p {
      font-size: 14px;
    }
  }
  