/* HeaderComponent.css */

.header-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.header-background {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.header-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.header-content {
    padding: 20px;
    background-color: white;
    position: relative;
    z-index: 2;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.header-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.header-title::before {
    content: "👨‍💻";
    margin-right: 10px;
}

.header-breadcrumb {
    color: #757575;
    font-size: 14px;
    margin-bottom: 20px;
}

.header-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-avatars {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
}

.avatar-count {
    font-size: 16px;
    color: #757575;
}

.share-btn {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.share-btn:hover {
    background-color: #0056b3;
}
/* HeaderComponent.css */

.children-content {
    padding: 20px;
    background-color: #f9f9f9;
}
