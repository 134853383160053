.sidebar {
    width: 250px;
    background-color: #f8f9fa;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.sidebar.open {
    transform: translateX(0);
}

.hamburger-icon {
    font-size: 30px;
    cursor: pointer;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1100;
    color: #333;
}

@media (min-width: 769px) {
    .hamburger-icon {
        display: none;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.profile-section {
    text-align: center;
    margin-bottom: 20px;
}

.profile-img {
    border-radius: 50%;
    margin-bottom: 10px;
}

.profile-section h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.profile-section p {
    margin: 0;
    font-size: 14px;
    color: #777;
}

.nav-menu ul {
    list-style: none;
    padding: 0;
    width: 100%;
}
.RenderDiv{
    margin-left: 38px;
}
.nav-menu ul li {
    width: 89%;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.nav-menu ul li:hover, .nav-menu ul li.active {
    background-color: #e9ecef;
    border-radius: 8px;
}

.icon {
    font-size: 20px;
    margin-right: 15px;
    color: #6c757d;
}

.text {
    font-size: 16px;
    color: #333;
}

.sidebar-footer {
    margin-top: auto;
    width: 100%;
}

.sidebar-footer ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

.sidebar-footer ul li {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sidebar-footer ul li:hover {
    background-color: #e9ecef;
    border-radius: 8px;
}

.sidebar-footer .icon {
    font-size: 20px;
    margin-right: 15px;
    color: #6c757d;
}

.sidebar-footer .text {
    font-size: 16px;
    color: #333;
}
