:root {
    --primary-color: #4CAF50;
    --secondary-color: #45a049;
    --background-color: #fff;
    --border-color: #ccc;
    --text-color: #333;
    --header-bg-color: #f0f0f0;
    --hover-bg-color: #f1f1f1;
    --button-bg-color: #4CAF50;
    --button-hover-bg-color: #45a049;
    --input-focus-border-color: #4CAF50;
    --error-color: #ff4c4c;
}

body {
    background-color: var(--background-color);
    font-family: 'Arial', sans-serif;
    color: var(--text-color);
    margin: 0;
    padding: 0;
}

.daily-report {
    width: 90%;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.daily-report h1, .daily-report h2 {
    color: var(--text-color);
    margin-bottom: 20px;
    text-align: center;
}

.report-controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.report-controls button {
    padding: 10px 15px;
    margin-left: 10px;
    border: none;
    background-color: var(--button-bg-color);
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 16px;
    margin-top: 10px;
}

.report-controls button:hover {
    background-color: var(--button-hover-bg-color);
    transform: scale(1.05);
}

.report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.report-header .logo img {
    height: 60px;
}

.report-header .report-info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 70px); /* Adjusted for logo */
}

.report-info label {
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--text-color);
}

.report-info input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.report-info input:focus {
    border-color: var(--input-focus-border-color);
    outline: none;
}

.workers-section, .day-work-section, .daily-check-section {
    margin-bottom: 20px;
}

.section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.section-title h2 {
    margin: 0;
}

.section-title button {
    padding: 5px 10px;
    background-color: var(--button-bg-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.section-title button:hover {
    background-color: var(--button-hover-bg-color);
    transform: scale(1.05);
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    table-layout: fixed;
}

th, td {
    padding: 10px;
    border: 1px solid var(--border-color);
    word-wrap: break-word;
}

th {
    background-color: var(--header-bg-color);
    text-align: left;
}

tr:nth-child(even) {
    background-color: var(--hover-bg-color);
}

.saveBtn, .closeBtn {
    width: 100%;
    margin-top: 10px;
}

.saveBtn:hover, .closeBtn:hover {
    transform: scale(1.05);
}

button {
    padding: 10px 15px;
    border: none;
    background-color: var(--button-bg-color);
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.3s;
}

button:hover {
    background-color: var(--button-hover-bg-color);
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .report-header {
        flex-direction: column;
    }

    .report-header .logo {
        margin-bottom: 20px;
    }

    .report-header .report-info {
        width: 100%;
    }

    .report-controls {
        justify-content: center;
    }

    .report-controls button {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }

    table {
        display: block;
        overflow-x: auto;
    }
}
