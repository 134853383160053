.point-list-page {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f7f8fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 237px;
}
/* PointListPage.css */

.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    background-color: #33799a;
    border: none;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    margin-right: 5px;
}

.tab.active {
    background-color: #33799a;
    border-bottom: 2px solid #007BFF;
    font-weight: bold;
}
/* PointListPage.css */

.page-layout {
    display: flex;
}

.point-list-page {
    flex-grow: 1;
    padding: 20px;
    background-color: #f5f5f5;
}

.back-btn {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
}

.back-btn:hover {
    background-color: #0056b3;
}

.view-btn {
    padding: 8px 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.view-btn:hover {
    background-color: #45a049;
}
/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
    .point-list-page{
        margin-left: 7px;
    }
}