/* src/components/TrackInventoryTable.css */
.inventory-container {
  padding: 20px;
  overflow-x: auto;
  margin-left: 220px; /* Ajuste essa margem de acordo com a largura do seu sidebar */
  z-index: 1; /* Certifique-se de que isso é suficiente para trazer para frente se necessário */
}

.close-button {
  float: right;
  margin-bottom: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.filters {
  margin-bottom: 20px;
}

.filters input {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.filters button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  margin-top: 11px;
  cursor: pointer;
}

.filters button:hover {
  background-color: #45a049;
}

.add-material {
  margin-bottom: 20px;
  margin-right: 10px;
  
}

.add-material input {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-material button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.add-material button:hover {
  background-color: #45a049;
}

.inventory-table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;  /* Evita que o texto dentro das células quebre */
}
.inventory-table th,
.inventory-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.inventory-table th {
  background-color: #f2f2f2;
}

.inventory-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.inventory-table tr:hover {
  background-color: #ddd;
}

.inventory-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #4CAF50;
  color: white;
}
.buttonShowHide{
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}
.inventory-table button {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.inventory-table button:hover {
  background-color: #ddd;
}
@media (max-width: 768px) {
  .inventory-container {
      margin-left: 0;
  }

  .inventory-table,
  .add-material input,
  .add-material button {
      width: 100%;
      margin-top: 12px;
  }
  .inventory-table th,
  .inventory-table td {
      padding: 8px 4px; /* Reduz o preenchimento para economizar espaço */
      font-size: 12px;  /* Reduz o tamanho da fonte para melhor ajuste */
  }
  .inventory-table {
    min-width: 600px; /* Define uma largura mínima para a tabela */
}

.inventory-table th,
.inventory-table td {
    padding: 8px 4px; /* Ajusta o preenchimento para menos espaço */
    font-size: 12px; /* Reduz o tamanho da fonte */
}
  .inventory-table .hide-on-mobile {
    display: none; /* Oculta colunas marcadas em telas pequenas */
}
}