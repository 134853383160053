@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.user-manager {
  font-family: 'Roboto', sans-serif;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  border-radius: 10px;
  background: #f9f9f9; /* Light grey background */
  margin-top: 20px;
}
.user-item {
  display: flex;
  align-items: center;
  gap: 20px;
  background: linear-gradient(to right, #f9f9f9, #e6e6e6);
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}
.user-photo img {
  width: 100px;  /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  border-radius: 50%; /* Circular image */
  object-fit: cover; /* Ensure the image covers the area */
}
.user-info {
  flex-grow: 1;
}
.search-bar input {
  width: 96%;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #007bff; /* Blue border */
  border-radius: 5px;
  font-size: 16px;
  padding-left: 23px;
}

.user-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: linear-gradient(to right, #f9f9f9, #e6e6e6);
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.button-group button {
  flex-grow: 1;
  background-color: #007bff; /* Primary button color */
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.button-group button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  box-shadow: 0 4px 12px rgba(0,76,182,0.3);
  transform: translateY(-2px);
}

@media (max-width: 600px) {
  .user-manager {
    padding: 10px;
    margin-bottom: 20px;
  }

  .search-bar input {
    font-size: 14px; /* Adjusting the font size for better readability */
    width: 70%;
    margin-left: 46px;
  }

  .user-item {
    flex-direction: column; /* Stack elements vertically for better viewing */
    padding: 13px;
  }

  .button-group {
    flex-direction: column; /* Stack buttons vertically to prevent crowding */
  }

  .button-group button {
    width: 100%; /* Full width buttons in mobile view */
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .user-item {
    align-items: start;
    flex-direction: column;
    margin-top: 47px;

  }

  .user-photo img {
    width: 80px;  /* Smaller size for mobile */
    height: 80px; /* Smaller size for mobile */
  }
  .button-group {
    flex-direction: row; 

    gap: 5px;
  }

  .button-group button {
    width: calc(50% - 5px); 
    padding: 8px;
    margin-top: 0;
  }
}
