/* src/components/ListaUsuarios/ListaUsuarios.css */

.lista-usuarios {
    width: 100%;
    overflow-x: auto;
  }
  
  .table-header {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }
  
  .search-box {
    padding: 8px;
    margin-bottom: 10px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ccc;
  }
  
  th {
    background-color: #f4f4f9;
  }
  
  .profile {
    display: flex;
    align-items: center;
  }
  
  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .status {
    font-weight: bold;
  }
  
  .status.pago {
    color: green;
  }
  
  .status.pendente {
    color: orange;
  }
  
  .details-button {
    padding: 9px 12px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    background-color: blue;
    cursor: pointer;
  }

  .details-button:hover {
    background-color: #0056b3;
  }
  @media (max-width: 768px) {
    .lista-usuarios table {
      display: block;
      overflow-x: auto; /* Permite rolagem horizontal */
    }
  
    .profile-pic {
      width: 30px; /* Reduz o tamanho das imagens */
      height: 30px;
    }
  
    .details-button {
      padding: 4px 8px; /* Reduz o padding dos botões */
    }
  }