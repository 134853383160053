:root {
    --primary-color: #4CAF50;
    --secondary-color: #45a049;
    --background-color: #f9f9f9;
    --border-color: #ccc;
    --text-color: #333;
    --header-bg-color: #f0f0f0;
    --hover-bg-color: #f1f1f1;
    --button-bg-color: #4CAF50;
    --button-hover-bg-color: #45a049;
    --input-focus-border-color: #4CAF50;
}

.worker-form {
    width: 90%;
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.worker-form h2 {
    color: var(--text-color);
    margin-bottom: 20px;
    text-align: center;
}

.worker-form .error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
}

.worker-form .success {
    color: green;
    text-align: center;
    margin-bottom: 10px;
}

.worker-form form div {
    margin-bottom: 15px;
}

.worker-form form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--text-color);
}

.worker-form form input[type="text"],
.worker-form form input[type="tel"],
.worker-form form select {
    width: 87%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.worker-form form input[type="text"]:focus,
.worker-form form input[type="tel"]:focus,
.worker-form form select:focus {
    border-color: var(--input-focus-border-color);
    outline: none;
}

.worker-form form button {
    width: 93%;
    padding: 10px;
    background-color: var(--button-bg-color);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.worker-form form button:hover {
    background-color: var(--button-hover-bg-color);
}
