.user-list-container {
  max-width: 900px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.user-list-container h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 28px;
  text-align: center;
  font-weight: 700;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.user-table th,
.user-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.user-table th {
  background-color: #f4f4f9;
  font-weight: 700;
  color: #555;
}

.user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.user-table tr:hover {
  background-color: #f1f1f1;
}

.user-table button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: #4f5d73;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.user-table button:hover {
  background-color: #1558b0;
  transform: translateY(-2px);
}

.user-table button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .user-list-container {
    padding: 10px;
    margin: 20px auto;
  }

  .user-table th,
  .user-table td {
    padding: 8px;
    font-size: 14px;
  }

  .user-table button {
    padding: 6px 12px;
    font-size: 12px;
  }
}
.user-list-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  position: relative;

}

h2, h3 {
  color: #333;
}
.UserListDiv input[type="text"] {
  width: 32%;
  padding: 8px;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 4px;
  width: calc(47% - 22px); /* Adjust input width */
}
.EmployeeList input[type="text"] {
  width: 32%;
  padding: 8px;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 4px;
  width: calc(47% - 22px); /* Adjust input width */
}

button {
  background-color: #5c67f2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #4a54e1; /* Darker shade for hover effect */
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th, .user-table td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.user-table th {
  background-color: #f4f4f4;
}

.user-table tbody tr:hover {
  background-color: #f9f9f9;
}


@media (max-width: 480px) {
  .user-table th,
  .user-table td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
  }

  .user-table tr {
    display: block;
    margin-bottom: 10px;
  }

  .user-table th {
    background-color: #f4f4f4;
    font-weight: 700;
  }

  .user-table td {
    flex: 1;
    padding: 8px;
    border: none;
    display: flex;
    flex-direction: column;
  }

  .user-table td button {
    margin-top: 5px;
    align-self: flex-start;
  }

  .user-table th:before, .user-table td:before {
    content: none;
  }
}