/* src/components/TimeBankService/TimeBankService.css */
.time-bank-service {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    margin-left: 257px;
    background-color: #ffffff;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    font-family: 'Arial', sans-serif; /* Fonte mais limpa e moderna */
}

.time-bank-service h2 {
    color: #2c3e50; /* Um azul escuro sutil */
    margin-bottom: 20px; /* Dá mais espaço entre o título e os campos de entrada */
}

.time-bank-service div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.time-bank-service input, .time-bank-service button {
    padding: 10px;
    margin: 5px 0; /* Consistência de margem vertical */
    border: 1px solid #bdc3c7; /* Cor de borda mais suave */
    border-radius: 5px;
    font-size: 16px;
}

.time-bank-service button {
    background-color: #3498db; /* Um azul mais vibrante */
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s ease-in-out; /* Adiciona transformação para feedback tátil */
}

.time-bank-service button:hover {
    background-color: #2980b9; /* Um azul um pouco mais escuro para o hover */
    transform: scale(1.03); /* Ligeira ampliação ao passar o mouse */
}

.time-bank-service ul {
    list-style: none;
    padding: 0;
    margin-top: 20px; /* Espaço antes da lista começar */
}

.time-bank-service li {
    padding: 10px;
    background-color: #ecf0f1; /* Cor de fundo sutil para cada item da lista */
    margin-top: 8px; /* Espaço entre itens */
    border-radius: 5px; /* Bordas arredondadas para os itens da lista */
}

@media (max-width: 768px) {
    .time-bank-service div {
        flex-direction: column;
    }
    .time-bank-service {
        max-width: 795px;
        margin: 45px auto;
        padding: 48px;
        margin-left: 2px;
        background-color: #ffffff;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        font-family: 'Arial', sans-serif; /* Fonte mais limpa e moderna */
    }
    .time-bank-service input, .time-bank-service button {
        width: 100%; /* Inputs e botões ocupam toda a largura em telas pequenas */
    }
}

.time-bank-service input:focus, .time-bank-service button:focus {
    border-color: #3498db; /* Cor de foco que combina com o botão */
    outline: none;
}
