.watch-list-hours {
    font-family: Arial, sans-serif;
    padding: 20px;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.toggle-buttons {
    margin-bottom: 20px;
    text-align: center;
}

.toggle-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.toggle-buttons button:hover {
    background-color: #0056b3;
}

section {
    margin-bottom: 30px;
}

input[type="text"],
input[type="date"],
input[type="time"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.filters {
    margin-bottom: 20px;
}

.filters label {
    display: block;
    margin-bottom: 10px;
}

.filters input[type="date"],
.filters input[type="time"] {
    display: inline-block;
    width: auto;
    margin-left: 10px;
}

.list {
    list-style: none;
    padding: 0;
}

.list-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view-button {
    padding: 5px 10px;
    border: none;
    background-color: #28a745;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.view-button:hover {
    background-color: #218838;
}

.filters {
    margin-bottom: 20px;
    display: flex;
    margin-top: 10px;
}

.filters label {
    display: block;
    margin-bottom: 10px;
}
.total-hours {
    background-color: #f8f9fa; /* Light background for contrast */
    border: 1px solid #ddd; /* Subtle border for definition */
    border-radius: 5px; /* Rounded corners */
    padding: 15px; /* Spacing inside the container */
    margin-bottom: 20px; /* Space below the container */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted effect */
}

.total-hours h4 {
    font-size: 1.25rem; /* Slightly larger font size */
    color: #333; /* Darker text color for better readability */
    margin: 0; /* Remove default margin */
}

.filters select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.details-list {
    list-style: none;
    padding: 0;
}

.details-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
}

.details-item p {
    margin: 5px 0;
}

.details-item p strong {
    display: inline-block;
    width: 150px;
}

