/* src/Dashboard.css */
.dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
  background: #ffffff; /* White background */
  border-radius: 15px;
  border: 1px solid #e5e7eb; /* Light grey border */
  margin-bottom: 20px;
}

.headerDashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: #1f2937; /* Dark background */
  color: #ffffff; /* White text */
  padding: 15px 20px;
  border-radius: 12px;
}

.header-left h1 {
  margin: 0;
  font-size: 24px;
  color: #fff;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.date {
  margin-right: 20px;
  font-size: 18px;
  color: #d1d5db; /* Light grey text */
}

.view-options i {
  font-size: 18px;
  color: #d1d5db; /* Light grey text */
  margin-left: 10px;
  cursor: pointer;
}

.view-options i:hover {
  color: #ffffff; /* White on hover */
}

.hours-cards {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.main-content {
  display: flex;
  gap: 20px;
}

.project-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-columns: repeat(3, 1fr); /* Ensure 3 columns */
  gap: 20px;
  flex: 2;
}

/* Responsividade */
@media (max-width: 768px) {
  .header-right {
    justify-content: flex-start;
    margin-top: 10px;
  }

  .view-options i {
    margin-left: 20px;
  }

  .date {
    margin-right: 10px;
  }

  .project-stats {
    flex-direction: column;
    align-items: flex-start;
  }

  .stat {
    margin-bottom: 10px;
  }

  .main-content, .hours-cards {
    flex-direction: column;
  }

  .project-cards {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns for smaller screens */
  }
}

@media (max-width: 480px) {
  header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-left, .header-right {
    flex: none;
    min-width: 100%;
    margin-bottom: 10px;
  }

  .project-stats {
    width: 100%;
    justify-content: space-between;
  }

  .view-options i {
    margin-left: 10px;
  }

  .date {
    margin-right: 0;
  }

  .project-cards {
    grid-template-columns: 1fr; /* Adjust to 1 column for smallest screens */
  }
}
