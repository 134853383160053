body {
  background-color: #f4f4f9;
  font-family: 'Arial', sans-serif;
}

.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
}

.image-container {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #1a73e8;
}

.image-container img {
  width: 80%;
  height: auto;
}

.login-container h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
}

.input-container {
  position: relative;
}

.input-container i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.input-container input {
  width: 100%;
  padding: 9px 6px 7px 3px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.input-container input:focus {
  border-color: #1a73e8;
  outline: none;
}

.input-container .fa-eye,
.input-container .fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group input {
  margin-right: 10px;
}

.error {
  color: #e53935;
  margin-bottom: 15px;
  font-size: 14px;
}

.buttonLogin {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: 700;
  color: white;
  background-color: #4f5d73;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.buttonRegister {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: 700;
  color: white;
  background-color: #4f5d73;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 10px;
}

button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #1558b0;
  transform: translateY(-2px);
}

button:active:not(:disabled) {
  transform: translateY(0);
}

.fa-spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
