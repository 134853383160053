/* src/styles/Card.css */
.card {
    background: #fff;
    border: 1px solid #2c3e50;
    border-radius: 28px;
    padding: 20px;
    width: 30%;
    margin: 20px;
    text-align: center;
}

.card button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.card button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .card {
        width: 80%;
    }
}
