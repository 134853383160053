.alert {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-family: Arial, sans-serif;
    color: #fff;
    opacity: 0.9;
    animation: slideIn 0.3s ease-in-out;
  }
  
  @keyframes slideIn {
    from {
      right: -100%;
    }
    to {
      right: 20px;
    }
  }
  
  .alert-info {
    background-color: #17a2b8;
  }
  
  .alert-success {
    background-color: #28a745;
  }
  
  .alert-warning {
    background-color: #ffc107;
    color: #212529;
  }
  
  .alert-error {
    background-color: #dc3545;
  }
  
  .close-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin-left: 20px;
    line-height: 1;
  }
  
  .close-btn:hover {
    color: #ddd;
  }
  