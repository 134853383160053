/* src/components/EmployeeHoursCard/EmployeeHoursCard.css */
.hours-card {
  background: #f9fafb; /* Light background */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e5e7eb;
  cursor: pointer; /* Add cursor pointer */
}

.hours-card:hover {
  transform: translateY(-5px);
  background: #f3f4f6; /* Slightly darker on hover */
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.card-header h3 {
  margin: 0;
  font-size: 24px;
  color: #1f2937; /* Darker text */
  margin-left: 10px;
}

.card-icon {
  font-size: 30px;
  color: #10b981; /* Green icon */
}

.card-body p {
  margin: 10px 0;
  color: #4b5563; /* Mid-dark text */
  font-size: 18px;
}

.progress-container {
  margin-top: 10px;
}

.progress-container span {
  font-size: 14px;
  color: #6b7280; /* Grey text */
}

.progress-bar {
  background: #e5e7eb; /* Light grey background */
  border-radius: 8px;
  overflow: hidden;
  margin-top: 5px;
  height: 10px;
}

.progress {
  height: 100%;
  background: #10b981; /* Green progress bar */
  border-radius: 8px;
  transition: width 0.3s;
}

/* Additional styles for the UserList container */
.user-list-container {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 1px solid #e5e7eb;
}
