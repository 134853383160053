/* src/ProjectCard.css */
.project-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s;
}

.project-card:hover {
  transform: translateY(-5px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #888;
}

.card-options {
  cursor: pointer;
}

.card-body {
  margin-top: 10px;
}

.card-body h3 {
  margin: 10px 0;
  font-size: 18px;
  color: #333;
}

.type {
  color: #888;
  font-size: 14px;
}

.progress-bar {
  background: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  margin: 15px 0;
  height: 10px;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #7c3aed, #4f46e5);
  border-radius: 8px;
  transition: width 0.3s;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.team-avatars {
  display: flex;
  gap: 5px;
}

.avatar {
  font-size: 24px;
  color: #7c3aed;
}

.days-left {
  background: #e0e7ff;
  padding: 5px 10px;
  border-radius: 5px;
  color: #4f46e5;
}
