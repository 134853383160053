/* PunchItem.css */

/* Container Principal */
.punch-item-container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

/* Cabeçalho */
.punch-item-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.company-info {
    display: flex;
    align-items: center;
}

.company-logo {
    max-width: 100px;
    margin-right: 15px;
}

.job-info {
    text-align: right;
}

/* Detalhes do Item */
.punch-item-details h3 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
}

.punch-item-details h4 {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: normal;
    color: #333;
}

.item-count {
    float: right;
    color: #333;
    font-weight: bold;
}

.item-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.info-group {
    flex-basis: 45%;
    margin-bottom: 15px;
}

.info-group.full-width {
    flex-basis: 100%;
}

.info-group p {
    margin: 0;
    font-size: 14px;
}

.item-image {
    margin-top: 20px;
    text-align: right;
}

.item-image img {
    max-width: 150px;
    border-radius: 4px;
}
