/* src/components/Admin/Admin.css */

.admin-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f9;
    box-sizing: border-box;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 36px;
    color: #333;
    font-weight: bold;
  }
  