/* src/styles/MainContent.css */
.main-content {
    /* margin-left: 220px; Same as sidebar width */

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap:0px
}
.project-name-bar {
    width: 100%;
    background-color: #2c3e50; /* Fundo mais escuro e sofisticado */
    color: #ecf0f1; /* Texto claro */
    padding: 15px 0; /* Aumenta um pouco o espaçamento vertical */
    text-align: center;
    font-size: 2rem; /* Tamanho da fonte maior para maior destaque */
    font-weight: 600; /* Fonte mais pesada para mais impacto */
  /* Mantém a barra fixa abaixo do header */
    top: 60px; /* Ajuste conforme a altura do seu header */
    left: 0;
    z-index: 1000; /* Mantém a barra acima de outros elementos */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra um pouco mais pronunciada */
    border-bottom: 3px solid #2980b9; /* Linha inferior de destaque */
    border-top: 3px solid #2980BA;
  }
  .project-address {
    font-size: 1rem; /* Fonte menor para o endereço */
    font-weight: 400; /* Peso da fonte mais leve para o endereço */
    margin-top: 5px; /* Pequeno espaçamento acima do endereço */
  }
  .main-content {
     /* Espaço superior para evitar que o conteúdo sobreponha a barra */
  }
  
  
@media (max-width: 768px) {
    .main-content {
        margin-left: 0;
    }
}
