/* src/components/Loading.css */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
}

.loading-bar {
  width: 80%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.loading-progress {
  height: 100%;
  background: linear-gradient(90deg, #3b5998, #8b9dc3);
  transition: width 0.1s ease, background-color 0.5s ease;
}

.loading-progress.complete {
  background: linear-gradient(90deg, #4caf50, #8bc34a); /* Verde indicando a conclusão */
}

.loading-text {
  font-size: 24px;
  color: #333;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.loading-message {
  font-size: 18px;
  color: #666;
  margin-top: 10px;
  text-align: center;
}
