.filter {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.filter button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.filter button:hover {
    background-color: #0056b3;
}
