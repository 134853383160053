/* OverviewComponent.css */

.overview {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.overview-item {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.overview-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.overview-label {
    font-size: 14px;
    color: #8b8b8b;
    margin-bottom: 5px;
}

.overview-value {
    font-size: 24px;
    font-weight: bold;
    color: #2a2a2a;
}

.overview-percentage {
    font-size: 14px;
    margin-top: 5px;
}

.overview-percentage.positive {
    color: #4caf50; /* Verde */
}

.overview-percentage.negative {
    color: #f44336; /* Vermelho */
}
.overview-percentage.positive {
    color: green;
}

.overview-percentage.negative {
    color: red;
}

.overview-icon {
    font-size: 24px;
    color: #bdbdbd;
}
