.profile-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.profile-header {
    display: flex;
    align-items: center;
}

.back-button {
    background: none;
    border: none;
    font-size: 20px;
    color: #ea0831;
}

.profile-progress {
    text-align: center;
    margin: 20px 0;
}

.profile-pic-container {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}

.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.edit-pic-button {
    position: absolute;
    bottom: 10px;
    right: 130px;
    background: none;
    border: none;
    font-size: 18px;
}

.profile-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.save-button {
    background-color: #007BFF;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #0056b3;
}
