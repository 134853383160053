/* TransactionListComponent.css */

/* Container principal */
.transaction-list {
    margin-top: 20px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Título da transação */
.transaction-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

/* Container de busca e filtro */
.transaction-search-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Campo de busca */
.transaction-search-filter input {
    width: 70%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.transaction-search-filter input:focus {
    border-color: #007BFF;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

/* Botão de filtro */
.transaction-search-filter .filter-btn {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.transaction-search-filter .filter-btn:hover {
    background-color: #0056b3;
}

/* Estilo da tabela */
.transaction-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #fff;
}

/* Cabeçalhos da tabela */
.transaction-table th {
    background-color: #f5f5f5;
    font-weight: bold;
    font-size: 14px;
    padding: 15px;
    text-align: left;
    border-bottom: 2px solid #e0e0e0;
    color: #666;
}

/* Células da tabela */
.transaction-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #eeeeee;
    color: #333;
}

/* Interação de hover na linha da tabela */
.transaction-table tr:hover {
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

/* Labels de status e prioridade */
.status-label,
.priority-label {
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    transition: all 0.3s ease;
}

.status-label.initiated {
    background-color: #ffc107;
    color: #fff;
}

.status-label.completed {
    background-color: #4caf50;
    color: white;
}

.priority-label.high {
    background-color: #f44336;
    color: white;
}

.priority-label.medium {
    background-color: #ff9800;
    color: white;
}

.priority-label.low {
    background-color: #2196f3;
    color: white;
}

/* Botão de visualização */
.view-btn {
    padding: 8px 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.view-btn:hover {
    background-color: #45a049;
}
