.search {
    margin-bottom: 20px;
}

.search input {
    width: 100%;
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-size: 16px;
}
