:root {
    --primary-color: #4CAF50;
    --secondary-color: #45a049;
    --background-color: #f9f9f9;
    --border-color: #ccc;
    --text-color: #333;
    --header-bg-color: #f0f0f0;
    --hover-bg-color: #f1f1f1;
    --button-bg-color: #4CAF50;
    --button-hover-bg-color: #45a049;
    --input-focus-border-color: #4CAF50;
}

.employee-form {
    width: 90%;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.employee-form h1, .employee-form h2 {
    color: var(--text-color);
    margin-bottom: 20px;
}

.employee-controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.employee-controls button {
    padding: 10px 15px;
    margin-left: 10px;
    border: none;
    background-color: var(--button-bg-color);
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    font-size: 16px;
    margin-top: 10px;
}

.employee-controls button:hover {
    background-color: var(--button-hover-bg-color);
}

.employee-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.employee-info label {
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--text-color);
}

.employee-info input[type="text"] {
    width: 93%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.employee-info input:focus {
    border-color: var(--input-focus-border-color);
    outline: none;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    table-layout: fixed;
}

th, td {
    padding: 10px;
    border: 1px solid var(--border-color);
    word-wrap: break-word;
}

th {
    background-color: var(--header-bg-color);
    text-align: left;
}

tr:nth-child(even) {
    background-color: var(--hover-bg-color);
}

@media (max-width: 768px) {
    .employee-controls {
        justify-content: center;
    }

    .employee-controls button {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }

    table {
        display: block;
        overflow-x: auto;
    }
}
