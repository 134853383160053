.dashboard-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main-view {
    flex-grow: 1;
    padding: 20px 15px;
    background-color: #ffffff;
    overflow-y: auto;
}

.main-view h1, .main-view h2 {
    color: #333;
    margin-bottom: 20px;
}

.main-view .filter-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.main-view .filter-section input {
    width: 100%;
    max-width: 300px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.main-view .filter-section button {
    padding: 8px 16px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-start;
}

.main-view .filter-section button:hover {
    background-color: #218838;
}

.main-view .table-container {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}

.main-view table {
    width: 100%;
    border-collapse: collapse;
}

.main-view table thead {
    background-color: #28a745;
    color: white;
}

.user-list-container, .daily-report {
    margin-left: 0;
    max-width: 100%;
}

.main-view table th, .main-view table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.main-view table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.main-view table tr:hover {
    background-color: #e9ecef;
}

.main-view .action-buttons {
    display: flex;
    gap: 10px;
}

.main-view .action-buttons button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.main-view .action-buttons .edit-button {
    background-color: #007bff;
    color: white;
}

.main-view .action-buttons .edit-button:hover {
    background-color: #0056b3;
}

.main-view .action-buttons .delete-button {
    background-color: #dc3545;
    color: white;
}

.main-view .action-buttons .delete-button:hover {
    background-color: #c82333;
}

@media (min-width: 769px) {
    .dashboard-page {
        flex-direction: row;
    }

    .user-list-container, .daily-report {
        margin-left: 269px;
        max-width: 738px;
    }

    .main-view .filter-section {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .main-view .filter-section input {
        width: 300px;
    }

    .main-view .filter-section button {
        align-self: auto;
    }
}
