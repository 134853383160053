.material-viewer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow-y: auto;
    margin-left: 248px;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
    .material-viewer {
        margin-left: 20px; /* Reduz margem em telas menores */
        padding: 10px; /* Diminui o padding para dispositivos móveis */
    }

    .filter-bar input {
        width: 100%; /* Faz o input ocupar toda a largura disponível */
        max-width: none; /* Remove o limite de largura */
    }

    .crud-form input[type="text"],
    .crud-form input[type="number"],
    .crud-form input[type="date"] {
        font-size: 12px; /* Reduz o tamanho da fonte para telas menores */
        padding: 8px;
    }

    table th, table td {
        padding: 6px;
        font-size: 12px; /* Reduz o tamanho da fonte nas tabelas para dispositivos móveis */
    }

    .pagination-btn {
        padding: 8px 12px; /* Reduz padding dos botões de paginação */
    }
}

.filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filter-bar select,
.filter-bar input {
    padding: 10px;
    border-radius: 50px;
    border: 1px solid #ddd;
    font-size: 14px;
    color: #333;
    transition: all 0.3s ease;
}

.filter-bar select:focus,
.filter-bar input:focus {
    border-color: #0062ff;
    box-shadow: 0 0 8px rgba(0, 98, 255, 0.2);
    outline: none;
}

.filter-bar input {
    width: 60%;
    max-width: 300px;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-around;
}

.tab {
    flex: 1;
    text-align: center;
    padding: 12px 0;
    background-color: #e0e0e0;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    color: #333;
    margin: 0 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-size: 16px;
}

.tab.active {
    background: linear-gradient(135deg, #0062ff 0%, #00c6ff 100%);
    color: white;
    box-shadow: 0 8px 20px rgba(0, 98, 255, 0.4);
}

.tab::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #00c6ff 0%, #0062ff 100%);
    z-index: -1;
    transition: transform 0.4s ease;
    transform: scaleX(0);
    transform-origin: left;
}

.tab.active::before {
    transform: scaleX(1);
    transform-origin: right;
}

.tab:hover {
    background: linear-gradient(135deg, #0056e3 0%, #00a9e3 100%);
    color: white;
    transform: translateY(-2px);
}

.tab:not(.active):hover::before {
    transform: scaleX(1);
}

.material-list {
    overflow-x: auto; /* Para permitir rolagem horizontal em tabelas grandes */
}

table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
}

th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #007bff;
    color: white;
}

tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

tbody tr:hover {
    background-color: #e9ecef;
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

tbody tr td {
    transition: all 0.3s ease;
    font-size: 15px;
    color: #555;
}

tbody tr:hover td {
    color: #333;
    font-weight: 600;
}

.edit-btn, .delete-btn {
    background-color: #28a745;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-btn {
    background-color: #dc3545;
}

.edit-btn:hover {
    background-color: #218838;
}

.delete-btn:hover {
    background-color: #c82333;
}

.used-btn {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.used-btn.used {
    background-color: #17a2b8;
    color: white;
}

.used-btn.not-used {
    background-color: #ffc107;
    color: #333;
}

.used-btn.used:hover {
    background-color: #138496;
}

.used-btn.not-used:hover {
    background-color: #e0a800;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-btn {
    padding: 10px 15px;
    margin: 0 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-btn.active {
    background-color: #0056b3;
}

.pagination-btn:hover {
    background-color: #0056b3;
}

.crud-form {
    margin-top: 40px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.filter-bar select,
.crud-form select {
    padding: 10px;
    border-radius: 50px;
    border: 1px solid #ddd;
    font-size: 14px;
    color: #333;
    transition: all 0.3s ease;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    background-color: white; /* Fundo branco para o dropdown */
    appearance: none; /* Remove a seta padrão do select */
    -webkit-appearance: none; /* Remove a seta padrão do select no Chrome/Safari */
    -moz-appearance: none; /* Remove a seta padrão do select no Firefox */
}

.filter-bar select:focus,
.crud-form select:focus {
    border-color: #0062ff;
    box-shadow: 0 0 8px rgba(0, 98, 255, 0.2);
    outline: none;
}

.filter-bar select {
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23000000" d="M2 0L0 2h4z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 10px;
}

.crud-form select {
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23000000" d="M2 0L0 2h4z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 10px;
}

.crud-form select {
    width: calc(100% - 20px); /* Ajusta o select para que se alinhe ao padding do formulário */
    max-width: none; /* Remove limite de largura para o dropdown */
}

.crud-form h3 {
    margin-bottom: 20px;
    font-weight: 600;
    color: #333;
}

.crud-form form {
    display: flex;
    flex-direction: column;
}

.crud-form input[type="text"],
.crud-form input[type="number"],
.crud-form input[type="date"] {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    transition: border-color 0.3s ease;
}

.crud-form input[type="text"]:focus,
.crud-form input[type="number"]:focus,
.crud-form input[type="date"]:focus {
    border-color: #007bff;
    outline: none;
}

.crud-form button {
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
    margin-top: 6px;
}

.crud-form button:hover {
    background-color: #218838;
}

.crud-form button[type="button"]:last-child {
    background-color: #dc3545;
}

.crud-form button[type="button"]:last-child:hover {
    background-color: #c82333;
}
