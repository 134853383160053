/* src/styles/Footer.css */
.footer {
  background: #333;
  color: white;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 10px;
}

@media (max-width: 768px) {
  .footer {
      position: relative;
      width: 95%;
  }
}
